import { IDependencyTelemetry } from '@microsoft/applicationinsights-common';

export function handleDependencyTelemetry(
  dependencyTelemetry: IDependencyTelemetry
) {
  if (!dependencyTelemetry.success) {
    if (
      +dependencyTelemetry.responseCode === 404 &&
      dependencyTelemetry.target
    ) {
      try {
        const targetUrl = new URL(dependencyTelemetry.target);
        if (
          /\/matches\/[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}\/skill$/.test(
            targetUrl.pathname
          )
        ) {
          // 404s for skill data are expected
          return false;
        }
      } catch (e) {
        // Ignore invalid URLs
      }
    }
    return true;
  }
  return false;
}
