import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  IDependencyTelemetry,
  RemoteDependencyData,
} from '@microsoft/applicationinsights-common';
import { handleDependencyTelemetry } from './dependency-telemetry-handler';

declare global {
  const WorkerGlobalScope: { new (): unknown } | undefined;
}

const isWorkerProcess =
  typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope;
const defaultBrowserHistory = {
  url: '/',
  location: { pathname: '' as string | undefined },
  listen: () => {},
  state: undefined as { url: string } | undefined,
};
let browserHistory = defaultBrowserHistory;

if (!isWorkerProcess && typeof window !== 'undefined') {
  browserHistory = { ...browserHistory, ...window.history };
  browserHistory.location.pathname = browserHistory?.state?.url;
}
const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    disableTelemetry:
      !isWorkerProcess &&
      (typeof window === 'undefined' ||
        window.location.hostname === 'localhost'),
    connectionString:
      'InstrumentationKey=04d2997c-6003-4de2-8ddf-696c9b445ad1;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
    extensions: [reactPlugin],
    enableAutoRouteTracking: false,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    maxAjaxCallsPerView: -1,
  },
});
if (isWorkerProcess || typeof window !== 'undefined') {
  appInsights.addTelemetryInitializer((envelope) => {
    if (envelope.baseType === RemoteDependencyData.dataType) {
      const dependencyTelemetry = envelope.baseData as IDependencyTelemetry;
      return handleDependencyTelemetry(dependencyTelemetry);
    }
  });
  appInsights.loadAppInsights();
}
